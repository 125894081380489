@keyframes ldio-vcys1rikotc {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  .ldio-vcys1rikotc div { box-sizing: border-box!important }
  .ldio-vcys1rikotc > div {
    position: absolute;
    width: 108.56px;
    height: 108.56px;
    top: 37.72px;
    left: 37.72px;
    border-radius: 50%;
    border: 5.5200000000000005px solid #000;
    border-color: #85a2b6 transparent #85a2b6 transparent;
    animation: ldio-vcys1rikotc 1.8518518518518516s linear infinite;
  }
  
  .ldio-vcys1rikotc > div:nth-child(2), .ldio-vcys1rikotc > div:nth-child(4) {
    width: 93.84px;
    height: 93.84px;
    top: 45.080000000000005px;
    left: 45.080000000000005px;
    animation: ldio-vcys1rikotc 1.8518518518518516s linear infinite reverse;
  }
  .ldio-vcys1rikotc > div:nth-child(2) {
    border-color: transparent #bbcedd transparent #bbcedd
  }
  .ldio-vcys1rikotc > div:nth-child(3) { border-color: transparent }
  .ldio-vcys1rikotc > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-vcys1rikotc > div:nth-child(3) div:before, .ldio-vcys1rikotc > div:nth-child(3) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 5.5200000000000005px;
    height: 5.5200000000000005px;
    top: -5.5200000000000005px;
    left: 46px;
    background: #85a2b6;
    border-radius: 50%;
    box-shadow: 0 103.04px 0 0 #85a2b6;
  }
  .ldio-vcys1rikotc > div:nth-child(3) div:after {
    left: -5.5200000000000005px;
    top: 46px;
    box-shadow: 103.04px 0 0 0 #85a2b6;
  }
  
  .ldio-vcys1rikotc > div:nth-child(4) { border-color: transparent; }
  .ldio-vcys1rikotc > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-vcys1rikotc > div:nth-child(4) div:before, .ldio-vcys1rikotc > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 5.5200000000000005px;
    height: 5.5200000000000005px;
    top: -5.5200000000000005px;
    left: 38.64px;
    background: #bbcedd;
    border-radius: 50%;
    box-shadow: 0 88.32000000000001px 0 0 #bbcedd;
  }
  .ldio-vcys1rikotc > div:nth-child(4) div:after {
    left: -5.5200000000000005px;
    top: 38.64px;
    box-shadow: 88.32000000000001px 0 0 0 #bbcedd;
  }
  .loadingio-spinner-double-ring-k6ez71mlp3 {
    width: 184px;
    height: 184px;
    display: inline-block;
    overflow: hidden;
  }
  .ldio-vcys1rikotc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .spinner{
    margin: auto;
    text-align: center;
  }
  .ldio-vcys1rikotc div { box-sizing: content-box; }

  .spinner{
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    background: rgba(255,255,255, 0.95);
    z-index: 99;
    align-items: center;
    display: flex;
    justify-content: center;
    top: 0px;
    left: 0px;
  }
