.hover-edit-field{
    .fa-edit{
        display: inline;
    }
    .fa-edit{
        display: block !important;
        position: absolute;
        left: 0px;
        top: 0px;
        cursor: pointer;
    }
    .col-md-6, .col-md-4{
        float: left;
        padding-left: 0px;
    }
    .form-control{
        height: 23px;
        width: min-content ;
    }
}
.hover-edit-field{
    position: relative;
    padding-left: 40px;
}