.table-responsive {
  position: relative;
}

.no-data {
  font-weight: 700;
  text-align: center;
}

.clear {
  clear: both;
}
.content {
  margin-top: 0px !important;
}
.signature{
  display: none;
}
.company-container {
  float: right;
  font-size: 50px;
  font-weight: 700;
  display: none;
  
  img {
    width: 210px;
  }
}
h2 {
  font-weight: 700;
  font-size: 40px;
}
.del-item {
  cursor: pointer;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: #f3f3f3;
  border: 1px solid #000;
  th {
    font-weight: 700 !important;
  }
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-color: #000;
}
.table th,
.table td {
  border-top: 1px solid #000;
}

.print-container h5,
.print-container b {
  display: none;
  .phone {
    width: 20px;
    margin: -5px 15px 0px 0px;
  }
}

.info-container {
  float: left;
  margin-top: 20px;
}
.additional-font {
  font-size: 31px;
  font-weight: 700;
}
.signature {
  min-height: 1px;
  width: 170px;
  background: #000;
  margin-top: 100px;
}
.company-container img {
  margin-bottom: 20px;
}