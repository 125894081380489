.container-fluid {
  padding: 0;
}
.date-container .print-content {
  display: inline-block;
}
.date-container > .print-content button {
  margin: 0 !important;
}

.order-title{
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.card {
  width: auto;
}
.details-modal {
  position: fixed;
  background: rgba(000, 000, 000, 0.3);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .details-modals-content {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border: 3px solid #949494;
    padding: 30px;
    position: relative;
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  .close-icon {
    color: #e3e3e3;
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 10px;
    font-weight: 700;
    cursor: pointer;
  }
  .table-responsive .table {
    display: none;
  }
}