.table-responsive {
    position: relative;
}

.no-data {
    font-weight: 700;
    text-align: center;
}

.hover-edit-field{
    .fa{
        margin: 3px;
        cursor: pointer;
    }
}